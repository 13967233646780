import { Tooltip } from 'antd'
import _ from 'lodash'
import { useState } from 'react'

interface MultiItemStringInterface {
  data: string[]
}

const mapType = (data: string[]) => {
  let arr = []

  if (data?.length > 0) {
    for (let i = 0; i < data.length; i++) {
      arr.push(data[i].trim())
    }
  }
  let uniqArr = _.uniq(arr)
  return uniqArr
}

const MultiItemString = (props: MultiItemStringInterface) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)
  let res = mapType(props.data)

  return (
    <>
      {res.length === 0 ? (
        <div>-</div>
      ) : res.length > 1 ? (
        <Tooltip
          title={<MultiItemSectionTooltip data={res} />}
          color="#FFF"
          overlayInnerStyle={{ borderRadius: 0, color: '#000' }}
          open={isShowTooltip}
          mouseEnterDelay={0.3}
        >
          <div
            className="rounded-full text-white bg-secondary text-center"
            style={{ height: 23, width: 23 }}
            onMouseLeave={() => {
              setIsShowTooltip(false)
            }}
            onMouseEnter={() => {
              setIsShowTooltip(true)
            }}
          >
            <div
              className="flex justify-center items-center font-bold"
              style={{ height: 23, fontSize: 15 }}
              data-testid="multi-item-multi"
            >
              {res.length}
            </div>
          </div>
        </Tooltip>
      ) : (
        <div data-testid="multi-item-single">{res}</div>
      )}
    </>
  )
}

export default MultiItemString

const MultiItemSectionTooltip = (props: MultiItemStringInterface) => {
  let res = mapType(props.data)
  return (
    <div>
      {res.map((item, idx) => {
        return <p key={idx}>{item}</p>
      })}
    </div>
  )
}
