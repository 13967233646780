import { Form, Input, Pagination, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RootState } from '../../../app/store'
import CLOSE_ICON from '../../../assets/icons/close.svg'
import SEARCH_ICON from '../../../assets/icons/search.svg'
import CustomDropdown from '../../../components/Common/CustomDropdown/CustomDropdown'
import SelectRender from '../../../components/Common/DataType/SelectType'
import MultiItemString from '../../../components/Common/MultiItemSection/MultiItemString'
import { PagingEnum } from '../../../enum'
import useResizeScreen from '../../../hook/useResizeScreen'
import { UtilisateurDataType } from '../../../models'
import { getAllUtilisateur } from '../../../redux/reducers/utilisateurSlice'
import { renderDigitsByNumber } from '../../../utils'
import { makeTableValue } from '../../../utils/utilisateur/makeTableValue'
import NewActionRow from '../../stock/components/ActionRow/NewActionRow'
import './Utilisateur.scss'

const UtilisateurScreen = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const pageIndex = searchParams.get('page-index')
  const pageSize = searchParams.get('page-size')
  const [isShowLess, setIsShowLess] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { currentListUtilisateur } = useSelector(
    (state: RootState) => state.utilisateur
  )
  const [data, setData] = useState<UtilisateurDataType[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  let height = useResizeScreen({
    pixels: 14,
  })
  const columns: ColumnsType<UtilisateurDataType> = [
    {
      title: <div className="text-center">Select</div>,
      dataIndex: 'select',
      key: 'select',
      width: 80,
      fixed: 'left',
      render: (_, row) => {
        return (
          <SelectRender
            isMainLine={true}
            isCheck={selectedRowKeys.includes(row.key)}
            onChangeChecked={(e) => {
              if (e.target.checked) {
                setSelectedRowKeys([...selectedRowKeys, row.key])
              } else {
                setSelectedRowKeys(
                  [...selectedRowKeys].filter((i) => i !== row.key)
                )
              }
            }}
            screenColor="blue"
          />
        )
      },
    },
    {
      title: 'Rôle',
      dataIndex: 'role',
      key: 'role',
      width: 180,
      render: (_, row) => {
        return (
          <>{`${row.role[0].toUpperCase()}${row.role.substring(
            1,
            row.role.length
          )}`}</>
        )
      },
    },
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
      width: 520,
      render: (_, row) => {
        return <>{row.nom}</>
      },
    },
    {
      title: 'Prénom',
      dataIndex: 'pre_nom',
      key: 'pre_nom',
      width: 520,
      render: (_, row) => {
        return <>{row.pre_nom || '-'}</>
      },
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 180,
      render: (_, row) => {
        return <>{row.code}</>
      },
    },
    {
      title: 'Mail',
      dataIndex: 'mail',
      key: 'mail',
      width: 600,
      render: (_, row) => {
        return <div title={row.mail}>{renderDigitsByNumber(row.mail, 40)}</div>
      },
    },
    {
      title: 'Client(s)',
      dataIndex: 'client',
      key: 'client',
      width: 600,
      render: (_, row) => {
        return <MultiItemString data={row.client || []} />
      },
    },
    {
      title: 'Société(s)',
      dataIndex: 'societe',
      key: 'societe',
      width: 600,
      render: (_, row) => {
        return <MultiItemString data={row.societe || []} />
      },
    },
    {
      title: 'Entrepôt(s)',
      dataIndex: 'entrepot',
      key: 'entrepot',
      width: 600,
      render: (_, row) => {
        return <MultiItemString data={row.entrepot || []} />
      },
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      render: (_, row) => {
        return <>-</>
      },
    },
    {
      title: 'Accès',
      dataIndex: 'access',
      key: 'access',
      width: 100,
      render: (_, row) => {
        return <>-</>
      },
    },
    {
      title: 'Statut',
      dataIndex: 'statut',
      key: 'statut',
      width: 100,
      render: (_, row) => {
        return <>{row.statut ? 'Actif' : 'Bloqué'}</>
      },
    },

    {
      title: 'Mise à jour',
      dataIndex: 'updateAt',
      key: 'updateAt',
      width: 200,
      render: (_, row) => {
        return <>{row.update_at}</>
      },
    },
  ]

  const rowClassName = (record: any, index: number) => {
    return index % 2 === 0 ? 'table-row-main-even' : 'table-row-main-odd'
  }

  useEffect(() => {
    dispatch(
      getAllUtilisateur({
        limit: Number(pageSize) || 25,
        offset: (Number(pageIndex) - 1) * Number(pageSize) || 0,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize])

  useEffect(() => {
    const listUser = makeTableValue(currentListUtilisateur.utilisateurList)
    setData(listUser)
  }, [currentListUtilisateur, pageIndex, pageSize])

  const onOpenModal = () => {
    navigate(
      `/configurations/utilisateur/utilisateur-create?page-index=${pageIndex}&page-size=${pageSize}`
    )
  }

  return (
    <div className="config-table-style">
      <div className="relative text-xs">
        <div
          className="research-bar bg-[#EEEEEE] mx-5 px-5 py-2 flex flex-row justify-between items-center"
          style={{
            borderBottomRightRadius: 7,
            borderBottomLeftRadius: 7,
          }}
        >
          <div className="grid grid-cols-7 gap-x-4 w-full">
            <Form.Item noStyle label="" name="" colon={false}>
              <Input className="research-bar-input" placeholder="Nom Client" />
            </Form.Item>

            <Form.Item noStyle label="" name="" colon={false}>
              <CustomDropdown
                width="w-full"
                type="default"
                placeholder="Toutes Sociétés"
                className="research-bar-input"
              />
            </Form.Item>
            <Form.Item noStyle label="" name="" colon={false}>
              <CustomDropdown
                width="w-full"
                type="default"
                placeholder="Tous Entrepôts"
                className="research-bar-input"
              />
            </Form.Item>
            <div className="flex flex-row gap-x-2">
              <img src={SEARCH_ICON} alt="masques" />
              <img src={CLOSE_ICON} alt="masques" />
            </div>
          </div>
        </div>
      </div>
      <div className="px-10 flex items-center justify-between">
        <div className="py-2 page-title-heading-1">UTILISATEURS</div>
        <NewActionRow
          onClickPlus={onOpenModal}
          isClickUp={false}
          isHideExport
        />
      </div>

      <div className="pl-10">
        <Table
          style={height}
          className="config-table config-table-wrapper  overflow-y-auto relative text-[#EBEBEB]"
          columns={columns}
          pagination={false}
          indentSize={0}
          loading={currentListUtilisateur.loading}
          dataSource={data}
          rowClassName={rowClassName}
        />
      </div>
      <Pagination
        className="custom-pagination reference-pagination table-wrapper"
        style={{ margin: '20px auto' }}
        locale={{ items_per_page: '' }}
        total={currentListUtilisateur.metadata.total || 0}
        defaultPageSize={PagingEnum.DEFAULT_PAGE_SIZE}
        showLessItems={isShowLess}
        pageSizeOptions={[
          PagingEnum.DEFAULT_PAGE_SIZE,
          PagingEnum.PAGE_SIZE_1,
          PagingEnum.PAGE_SIZE_2,
        ]}
        pageSize={Number(pageSize)}
        current={Number(pageIndex)}
        showSizeChanger
        showTotal={(total, range) =>
          `${range[0] >= 0 ? range[0] : 0}-${
            range[1] >= 0 ? range[1] : 0
          } sur ${total}`
        }
        onChange={(page, size) => {
          navigate(
            `/configurations/utilisateur?page-index=${page}&page-size=${size}`
          )
          setIsShowLess(() => {
            return page > 4
          })
        }}
      />
    </div>
  )
}

export default UtilisateurScreen
