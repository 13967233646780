import moment from 'moment'
import { UserRole } from '../../enum/UserRole'
import { UtilisateurDataType, UtilisateurResponse } from '../../models'
import { t } from 'i18next'

export const makeTableValue = (array: UtilisateurResponse[]) => {
  let list: UtilisateurDataType[] = []
  if (array) {
    array.forEach((e, index) => {
      list.push(makeRowParentValue(e, index))
    })
  }
  return list
}
const makeRowParentValue = (u: UtilisateurResponse, index: number) => {
  let createAt = (u?.create_at || 0) * 1000
  let updateAt = (u?.update || 0) * 1000
  let value: UtilisateurDataType = {
    key: index,
    select: true,
    nom: u.nom || '',
    code: u.code || '',
    role: u.role || UserRole.ADMINISTRATEUR,
    pre_nom: u.pre_nom || '',
    mail: u.mail || '',
    client: u.client,
    societe: u.company,
    entrepot: u.warehouse,
    action: '',
    access: '',
    statut: u.statut,
    create_at: moment(createAt).format(t('time-format')),
    update_at: moment(updateAt).format(t('time-format')),
  }
  return value
}
